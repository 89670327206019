<template>
  <div class="meal-card-manage">
    <statistics :isMealCard="true"></statistics>
    <file-list :isMealCard="true"></file-list>
    <edit-modal :isMealCard="true"></edit-modal>
    <delete-modal></delete-modal>
    <card-detail></card-detail>
  </div>
</template>

<script>
import statistics from '@/components/fileManage/Statistics'
import fileList from '@/components/fileManage/FileList'
import editModal from '@/components/fileManage/EditModal'
import deleteModal from '@/components/fileManage/DeleteModal'
import cardDetail from '@/components/fileManage/CardDetail'
export default {
  components: {
    statistics,
    fileList,
    editModal,
    deleteModal,
    cardDetail
  }
}
</script>

<style lang="less">
.meal-card-manage {
  .total {
    margin: 0 -12px;
    .item {
      border: 1px solid #e7e7eb;
      background: linear-gradient(180deg, #ffffff, #fbfbfb);
      border-radius: 6px;
      margin: 0 12px;
      line-height: 1;
      .bd {
        padding: 16px;
        display: flex;
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          img {
            width: 100%;
          }
        }
        .tenu {
          .num {
            margin-top: 8px;
            margin-bottom: -3px;
            b {
              font-size: 30px;
            }
            span {
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
            }
          }
        }
      }
      .fd {
        border-top: 1px solid #e7e7eb;
        padding: 4px 0;
        .con {
          padding: 6px 16px;
          flex: 1;
          display: flex;
          align-items: center;
          border-right: 1px dotted #e7e7eb;
          .num {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
  .fili {
    margin-top: 24px;
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      font-size: 20px;
      padding: 16px 0 12px;
    }
    .filter {
      border-radius: 2px;
      border: 1px solid #e7e7eb;
      padding: 8px 0;
      .item {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        .title {
          font-weight: bold;
          width: 50px;
        }
        .value {
          span {
            display: inline;
            margin-right: 24px;
            &:hover,
            &.active {
              color: #4283fc;
              cursor: pointer;
            }
          }
        }
      }
    }
    .fun {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .search {
        .ant-input {
          height: 44px;
        }
        .anticon {
          font-size: 18px;
        }
      }
      .button {
        display: flex;
        align-items: center;
        .ant-btn {
          width: 80px;
          margin-left: 16px;
        }
      }
    }
    .list {
      .table {
        margin: 16px 0;
      }
    }
  }
}
.cardinfo-model{
  margin:-24px 0;
  .row{
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px dotted #e8e8e8;
    &:last-child{
      border: none;
    }
    .title{
      min-width: 100px;
      color:#666;
    }
    .value{
      flex: 1;
      .price{
        font-size: 20px;
      }
    }
  }
}
.state-desc {
  font-size: 13px;
  line-height: 2;
}
</style>
