<template>
    <a-modal
      title="饭卡详情"
      :visible="showModal"
      @ok="clickOk"
      @cancel="clickOk"
      :footer="null"
      class="customermodel"
      width="400px"
      :centered="true"
    >
    <div class="cardinfo-model">
      <div class="row">
        <div class="title">卡号：</div>
        <div class="value">{{data._id}}</div>
      </div>
      <div class="row">
        <div class="title">微信昵称：</div>
        <div class="value">{{data.nickname}}</div>
      </div>
      <div class="row">
        <div class="title">姓名：</div>
        <div class="value">{{data.name}}</div>
      </div>
      <div class="row">
        <div class="title">手机号码：</div>
        <div class="value">{{data.mobile}}</div>
      </div>
      <div class="row">
        <div class="title">余额：</div>
        <div class="value"><b class="price fprimary">{{data.balance * 0.01}}</b></div>
      </div>
    </div>
    </a-modal>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  data () {
    return {
      data: {},
      showModal: false
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    async showCardDetailModal (cardNum) {
      const query = '?cardNum=' + cardNum + '&schoolId=' + this.currentSchool._id
      const response = await this.$http.get(apis.user.mealCardInfo + query)
      if (response.body.err) return
      this.showModal = true
      this.data = response.body.card || { _id: cardNum }
    },
    async clickOk () {
      this.showModal = false
    }
  },
  created () {
    this.eventBus.$on('show-carddetail-modal', this.showCardDetailModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-carddetail-modal', this.showCardDetailModal)
  }
}
</script>
